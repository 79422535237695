import Link from "next/link";
import { useMemo, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import styles from "./Footer.module.scss";

const menus = {
  catalog: "catalog",
  shop: "shop",
  clients: "clients",
  partners: "partners",
};

export default function Footer({ host = "" }) {
  const [menuState, setMenuState] = useState("");

  const { contactsData } = useSelector((state) => state.contacts);

  const phone = useMemo(() => {
    return contactsData?.PHONE;
  }, [contactsData]);

  return (
    <div className={styles.Footer}>
      <div className="container">
        <div className={styles.Colomns}>
          <div>
            <div className={styles.Info}>
              <Link href="/">
                <a className={styles.Logo}>
                  <img src="/logo3.svg" />
                </a>
              </Link>
              <a
                href={`tel:${phone}`}
                className={contactsData.CALL_TOUCH_CODE}
                onClick={() => {
                  if (
                    window.location.href.includes(
                      "vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_moskve",
                    )
                  ) {
                    if (typeof ym !== "undefined") {
                      // eslint-disable-next-line no-undef
                      ym(20518312, "reachGoal", "mskRegPhoneClick");
                    }
                  } else if (
                    window.location.href.includes(
                      "vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_sankt-peterburge",
                    )
                  ) {
                    if (typeof ym !== "undefined") {
                      // eslint-disable-next-line no-undef
                      ym(20518312, "reachGoal", "spbRegPhoneClick");
                    }
                  }
                }}
              >
                {phone}
              </a>
              <a href="mailto:info@farkop.ru">info@farkop.ru</a>
            </div>

            <div className={styles.Soc}>
              <span>Мы в соцсетях</span>
              <div className={styles.SocList}>
                <Link href="https://www.instagram.com/farkop.ru/">
                  <a>
                    <img src="/soc/2.svg"></img>
                  </a>
                </Link>
                <Link href="https://vk.com/farkopru">
                  <a>
                    <img src="/soc/3.svg"></img>
                  </a>
                </Link>
                <Link href="https://www.facebook.com/%D0%A4%D0%B0%D1%80%D0%BA%D0%BE%D0%BF-%D0%A1%D0%9F%D0%B1-451260541645692/">
                  <a>
                    <img src="/soc/1.svg"></img>
                  </a>
                </Link>
                <Link href="https://www.youtube.com/channel/UCvIC3w49aEkOLBH4gG4iCbw">
                  <a>
                    <img src="/soc/4.svg"></img>
                  </a>
                </Link>
              </div>
              <span className={styles.Meta}>
                *Деятельность Meta (соцсети Facebook и Instagram) запрещена в
                России как экстремистская организация.
              </span>
            </div>
          </div>
          <div
            className={clsx(
              styles.MenuBlock,
              menuState === menus.shop && styles.MenuBlockActive,
            )}
          >
            <span
              className={styles.ColomnTitle}
              onClick={() => {
                setMenuState(menuState === menus.shop ? "" : menus.shop);
              }}
            >
              Магазин
            </span>
            <ul>
              <li>
                <Link href="/about/">О компании</Link>
              </li>
              {["msk.farkop.ru", "spb.farkop.ru"].includes(host) && (
                <li>
                  <Link href="/services/">Услуги</Link>
                </li>
              )}
              <li>
                <Link href="/reviews/">Отзывы</Link>
              </li>
              <li>
                <Link href="/articles/">Статьи и новости</Link>
              </li>
              <li>
                <Link href="/contacts/">Контакты</Link>
              </li>
              {["msk.farkop.ru", "spb.farkop.ru"].includes(host) && (
                <li>
                  <Link href="/shops/">Магазины</Link>
                </li>
              )}
            </ul>
          </div>
          <div
            className={clsx(
              styles.MenuBlock,
              menuState === menus.clients && styles.MenuBlockActive,
            )}
          >
            <span
              className={styles.ColomnTitle}
              onClick={() => {
                setMenuState(menuState === menus.clients ? "" : menus.clients);
              }}
            >
              Покупателям
            </span>
            <ul>
              <li>
                <Link href="/faq/">Вопросы и ответы</Link>
              </li>
              <li>
                <Link href="/payment/">Оплата</Link>
              </li>
              <li>
                <Link href="/delivery/">Доставка</Link>
              </li>
              <li>
                <Link href="/refund/">Возврат товара</Link>
              </li>
              <li>
                <Link href="/warranty/">Гарантия</Link>
              </li>
              <li>
                <Link href="/certificates/">Сертификаты</Link>
              </li>
              <li>
                <Link href="/job/">Вакансии</Link>
              </li>
            </ul>
          </div>
          <div
            className={clsx(
              styles.MenuBlock,
              menuState === menus.partners && styles.MenuBlockActive,
            )}
          >
            <span
              className={styles.ColomnTitle}
              onClick={() => {
                setMenuState(
                  menuState === menus.partners ? "" : menus.partners,
                );
              }}
            >
              Партнеры
            </span>
            <ul>
              <li>
                <Link href="/partners/cooperation/">Сотрудничество</Link>
              </li>
              <li>
                <Link href="/partners/opt/">Оптовые продажи</Link>
              </li>
              <li></li>
            </ul>
            <div className={styles.Vidjets}>
              <iframe
                src="https://yandex.ru/sprav/widget/rating-badge/1147929148"
                width="150"
                height="50"
                frameBorder="0"
              />

              <a
                className={styles.Ozon}
                href="https://www.ozon.ru/seller/farkop-ru-174926/products/?miniapp=seller_174926"
                target="_blank"
                rel="noreferrer"
              >
                Мы на
                <img src="/ozon.png" />
              </a>
              <a
                className={styles.Drive2}
                href="https://www.drive2.ru/users/farkopru/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/drive2.png" />
              </a>
            </div>
          </div>
        </div>

        {host === "msk.farkop.ru" && (
          <div className={styles.Link}>
            <a href="https://spb.farkop.ru/">
              Купить фаркоп с установкой можно так же в Санкт-Петербурге
            </a>
          </div>
        )}
        {host === "spb.farkop.ru" && (
          <div className={styles.Link}>
            <a href="https://msk.farkop.ru/">
              Купить фаркоп с установкой можно так же в Москве
            </a>
          </div>
        )}

        <div className={styles.Req}>
          Индивидуальный предприниматель Асадуллин Ринат Расимович
          <br />
          ИНН: 110603860118
          <br />
          ОГРНИП: 317784700336405
          <br />
          192249, Российская Федерация, г. Санкт-Петербург, проспект
          Александровской Фермы, д. 29, лит. ВГ
          <br />
        </div>

        <div className={styles.Privacy}>
          <Link href="/privacy/">Пользовательское соглашение</Link>
        </div>

        <div className={styles.Copy}>
          <div>© 2010–{new Date().getFullYear()} Фаркоп.ру</div>
          <div className={styles.Payments}>
            <img src="/payments/1.png" />
            <img src="/payments/2.png" />
            <img src="/payments/3.png" />
            <img src="/payments/4.png" />
            <img src="/payments/5.png" />
            <img src="/payments/7.png" />
            <img src="/payments/8.png" />
          </div>
        </div>
        <div className={styles.Info}>
          Вся информация на данном сайте несёт исключительно информационный
          характер и ни при каких условиях не является публичной офертой,
          определяемой положениями Статьи 437 (2) ГК РФ.
        </div>
      </div>
    </div>
  );
}
