import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import clsx from "clsx";
import { fetchSections } from "/redux/action-creaters/sections";
import { fetchBrands } from "/redux/action-creaters/brands";
import Icon from "../../../common/icon";
import styles from "./index.module.scss";

export default function TopSections() {
  const { sections } = useSelector((state) => state.sections);
  const { items } = useSelector((state) => state.brands);
  const [filterUrl, setFilterUrl] = useState("");

  const [rootId, setRootId] = useState(-1);
  const dispatch = useDispatch();

  const sectionsTree = useMemo(() => {
    // eslint-disable-next-line no-use-before-define
    return getSectionsTree(sections);
  }, [sections]);

  useEffect(() => {
    if (sections.length === 0) {
      dispatch(fetchSections());
    }
    if (items.length === 0) {
      dispatch(fetchBrands());
    }
  }, []);

  useEffect(() => {
    if (typeof localStorage === "object") {
      setFilterUrl(localStorage.getItem("filterUrl") ?? "");
    }
  });

  return (
    <div className={styles.TopSections}>
      <div className={styles.RootSections}>
        <ul>
          {sectionsTree.map((section, id) => (
            <li
              key={section.ID}
              onClick={() => {
                if (section.CHILD.length > 0) {
                  setRootId(id);
                }
              }}
              className={clsx(id === rootId && styles.Selected)}
            >
              {section.CHILD.length === 0 ? (
                <>
                  <Link href={section.SECTION_PAGE_URL + filterUrl}>
                    <a>{section.NAME}</a>
                  </Link>
                </>
              ) : (
                <>
                  {section.NAME}
                  <Icon
                    id={"arrow"}
                    width={8}
                    height={14}
                    fill={id === rootId ? "#1247a5" : "#333"}
                  />
                </>
              )}
            </li>
          ))}
          <li
            onClick={() => setRootId(999)}
            className={clsx(rootId === 999 && styles.Selected)}
          >
            Бренды
            <Icon
              id={"arrow"}
              width={8}
              height={14}
              fill={rootId === 999 ? "#1247a5" : "#333"}
            />
          </li>
        </ul>
      </div>
      <div className={styles.ChildSections}>
        {rootId === -1 && (
          <div className={styles.Sections}>
            {sectionsTree.map((section) => (
              <Link
                href={
                  section.SECTION_PAGE_URL +
                  (section.CHILD?.length > 0 ? "" : filterUrl)
                }
                key={section.ID}
              >
                <a className={styles.Section}>
                  <div>
                    <img
                      src={section.DETAIL_PICTURE_PATH}
                      alt={section.NAME}
                      title={section.NAME}
                    />
                  </div>
                  <span>{section.NAME}</span>
                </a>
              </Link>
            ))}
          </div>
        )}
        {sectionsTree.length > 0 && sectionsTree[rootId]?.NAME && (
          <>
            <div className={styles.Title}>{sectionsTree[rootId].NAME}</div>
            <div className={styles.Sections}>
              {sectionsTree[rootId].CHILD.map((section) => (
                <Link
                  href={
                    section.SECTION_PAGE_URL +
                    (section.CHILD?.length > 0 ? "" : filterUrl)
                  }
                  key={section.ID}
                >
                  <a className={styles.Section}>
                    <div>
                      <img
                        src={section.DETAIL_PICTURE_PATH}
                        alt={section.NAME}
                        title={section.NAME}
                      />
                    </div>
                    <span>{section.NAME}</span>
                  </a>
                </Link>
              ))}
            </div>
          </>
        )}

        {rootId === 999 && (
          <>
            <div className={styles.Title}>Бренды</div>
            <div className={styles.SectionOneBrand}>
              {items?.map((brand) => (
                <Link key={brand.name} href={brand.detalPageUrl}>
                  <a>
                    <div>
                      <img src={brand.pictureSrc} />
                    </div>
                    <span>{brand.name}</span>
                  </a>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function getSectionsTree(sections, parent = null) {
  return Object.values(sections)
    .filter((section) => +section.IBLOCK_SECTION_ID === +parent)
    .map((section) => {
      return {
        ...section,
        CHILD:
          section.HAS_CHILDS === "Y"
            ? getSectionsTree(sections, section.ID)
            : [],
      };
    })
    .sort((a, b) => a.SORT - b.SORT);
}
