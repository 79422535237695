import { Api } from "/api";

export const fetchBrands = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const response = await Api.server.getBrands(reset);
    dispatch({ type: "FETCH_BRANDS", payload: response });
  };
};

export const fetchAllBrands = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const response = await Api.server.getBrands(reset);
    dispatch({ type: "FETCH_BRANDS", payload: response });
  };
};

export const fetchBrand = (Redis = false, code, reset = false) => {
  return async (dispatch) => {
    const data = await Api.brands.getBrand(Redis, code, reset);
    dispatch({ type: "FETCH_BRAND_DETAIL", payload: data });
  };
};
