import { useState } from "react";
import { useDispatch } from "react-redux";

import Icon from "@/components/common/icon";
import { showPopup } from "@/redux/action-creaters/popup";
import { popupTypes } from "@/redux/reducers/popupReducer";

import styles from "./index.module.scss";
import Tg from "./tg";
import Wp from "./wp";

export default function FeedBack({ isScrolled }: any) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {/* <div
                className={styles.FeedBack}
                onClick={() => setIsActive(true)}
            >
                <Icon id='feedback' size='50'/>
                <span className={styles.Info}>Обратная связь</span>
           </div> */}
      {isActive && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={styles.FeedBack__Wrapper}
          onClick={() => setIsActive(!isActive)}
        >
          <div>
            <div>
              <span className={styles.Info}>WhatsApp</span>
              <Wp />
            </div>
            <div>
              <span className={styles.Info}>Telegram</span>
              <Tg />
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={styles.FeedBack__Backcall}
              onClick={() => dispatch(showPopup(popupTypes.backCall))}
            >
              <span className={styles.Info}>Обратный звонок</span>
              <Icon id="phone" width={30} height={30} fill="#fff" />
            </div>
            <div className={styles.FeedBack__Close}>
              <span className={styles.Info}>Закрыть</span>
              <Icon id="close" width={35} height={35} fill="#fff" />
            </div>
          </div>
        </div>
      )}
      {!isActive && isScrolled && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={styles.ScrollTop}
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <svg viewBox="0 0 1024 1024" version="1.1">
            <path d="M755.626667 481.706667l-213.333334-213.333334a42.666667 42.666667 0 0 0-14.08-8.96 42.666667 42.666667 0 0 0-32.426666 0 42.666667 42.666667 0 0 0-14.08 8.96l-213.333334 213.333334a42.666667 42.666667 0 0 0 60.586667 60.586666L469.333333 401.493333V725.333333a42.666667 42.666667 0 0 0 85.333334 0V401.493333l140.373333 140.8a42.666667 42.666667 0 0 0 60.586667 0 42.666667 42.666667 0 0 0 0-60.586666z" />
          </svg>
        </div>
      )}
    </>
  );
}
