import Button from "/components/common/button";
import Icon from "../../../common/icon";
import { useDispatch, useSelector } from "react-redux";
import { setShowTopSections } from "/redux/action-creaters/pageState";
import styles from "./index.module.scss";
import clsx from "clsx";
import { useEffect } from "react";

export default function Catalog({ isScrolled }) {
  const dispatch = useDispatch();
  const { isShowTopSections } = useSelector((state) => state.pageState);

  const toggleTopCatalog = () => {
    dispatch(setShowTopSections(!isShowTopSections));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isShowTopSections) {
      dispatch(setShowTopSections(false));
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isShowTopSections]);

  return (
    <Button
      label="Каталог"
      onClick={toggleTopCatalog}
      type={isShowTopSections ? "blackSelected" : "black"}
      before={
        <Icon
          id={isShowTopSections ? "close" : "burger"}
          fill={isShowTopSections ? "#333" : "#333"}
          width={22}
          height={22}
        />
      }
      customStyles={clsx(
        styles.CatalogButton,
        isScrolled && styles.CatalogButtonScrolled,
      )}
    />
  );
}
