import Button from "/components/common/button";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { showPopup } from "/redux/action-creaters/popup";
import { popupTypes } from "/redux/reducers/popupReducer";
import React, { useMemo } from "react";
import styles from "./index.module.scss";
import Icon from "/components/common/icon";

export default React.memo(function User({ user }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const isAuthorized = useMemo(() => {
    return Boolean(user?.ID);
  }, [user]);

  const userName = useMemo(() => {
    if (isAuthorized) {
      if (user.NAME) {
        return user.NAME;
      }
      return "Личный кабинет";
    }
    return "Вход и регистрация";
  }, [isAuthorized]);

  return (
    <>
      <Button
        label={userName}
        type="outline"
        onClick={() => {
          if (isAuthorized) {
            router.push("/personal/");
          } else {
            dispatch(showPopup(popupTypes.auth));
          }
        }}
        customStyles={styles.Button}
      />
      <Button
        type={"none"}
        onClick={() => {
          if (isAuthorized) {
            router.push("/personal/");
          } else {
            dispatch(showPopup(popupTypes.auth));
          }
        }}
        before={
          <Icon
            id="user"
            fill={!isAuthorized ? "#93979b" : "#1247a5"}
            width={16}
            height={22}
            customStyles={styles.Icon}
          />
        }
        customStyles={styles.ButtonMobile}
      />
    </>
  );
});
